<template>
  <div>
    <b-container fluid class="p-5">
      <b-row>
        <b-col :md="5">
          <b-card class="shadow p-3 m-2 mb-4">
            <h5>
              <b-icon icon="search"></b-icon>
              查找权限
            </h5>
            <div>
              <b-form-group
                  label="Search"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-3"
              >
                <b-input-group>
                  <b-form-input
                      id="filter-input"
                      v-model="input"
                      type="search"
                      placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button variant="primary" @click="searchAuthorityById">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card>
          <b-card class="shadow p-3 m-2 mb-4">
            <h5>
              <b-icon icon="arrow-up-circle"></b-icon>
              创建权限
            </h5>
            <div>
              <b-form-group
                  label="Create"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-3"
              >
                <b-input-group>
                  <b-form-input
                      id="filter-input"
                      v-model="addForm"
                      type="search"
                      placeholder="Type to name a new authority"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button variant="primary" @click="addAuthority">Create</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card>
          <b-card class="shadow p-3 m-2">
            <h5>
              <b-icon icon="person-check"></b-icon>
              我创建的权限
            </h5>
            <div>
              <b-table striped hover :fields="myCreateAuthorityFields" :items="myCreateAuthority">
                <template #cell(action)="row">
                  <b-button variant="outline-danger" size="sm" @click="revokeAuthority(row.item.id)" class="mr-2">
                    <b-icon icon="x"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-card>
        </b-col>
        <b-col :md="7">
          <b-card class="shadow p-3 m-2">
            <h5>
              <b-icon icon="person-check"></b-icon>
              我的权限
              <b-button class="mx-3" variant="outline-primary" @click="goSearchStaff()">分配权限</b-button>
            </h5>
            <div>
              <b-table striped hover :fields="myAuthorityFields" :items="myAuthority"></b-table>
            </div>
          </b-card>

          <b-card class="shadow m-2 p-3 mt-4">
            <h5>
              <b-icon icon="people"></b-icon>
              我授予的权限
            </h5>
            <el-select class="mt-3 w-75" v-model="selected" placeholder="请选择" @change="showStaffsByAuthority">
              <el-option
                  v-for="item in myAuthority"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <!-- Main table element -->
            <b-table
                :items="staffList"
                :fields="fields"
                :current-page="1"
                :per-page="20"
            >
              <template #cell(action)="row">
                <b-button variant="outline-danger" size="sm" @click="revokeAuthority(row.item.id)" class="mr-2">
                  <b-icon icon="x"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  showMyAuthorities,
  showAuthoritiesOwnedByMe,
  showAuthorityById,
  createAuthority,
  showGranteesGrantedByMeByAuthorityId, revokeAuthority, removeAuthority
} from "@/api";

export default {
  name: "AuthorityManage",
  data() {
    return {
      selected: '',
      options: [],
      staffList: [],
      addForm: '',
      input: '',
      myCreateAuthority: [],
      myAuthority: [],
      grantedAuthorities: [],
      myAuthorityFields: [{key: 'id', label: '权限号'}, {key: 'name', label: '权限名'}, {key: 'ownerId', label: '归属者'}],
      fields: [
        {key: 'id', label: '工号',},
        {key: 'name', label: '姓名'},
        {
          key: 'organizationId',
          label: '组织号',
        },
        {key: 'action', label: '操作'}
      ],
      myCreateAuthorityFields: [{key: 'id', label: '权限号'}, {key: 'name', label: '权限名'}, {key: 'action', label: '操作'}],
    }
  },
  created() {
    this.getMyAuthority()
    this.getAuthoritiesOwnedByMe()
  },
  methods: {
    goSearchStaff(){
      this.$router.push({path:'/searchStaff'})
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    getMyAuthority() {
      showMyAuthorities().then(res => {
        if (res.data.status) {
          this.myAuthority = res.data.data
          this.myAuthority.forEach(m => {
            this.options.push({value: m.id, text: m.name})
          })
        } else {
          this.$message.error('获取我的权限失败!')
        }
      })
    },
    getAuthoritiesOwnedByMe() {
      showAuthoritiesOwnedByMe(1, 20).then(res => {
        if (res.data.status) {
          this.myCreateAuthority = res.data.data.content
        } else {
          this.$message.error('获取我创建的权限失败')
        }
      })
    },
    searchAuthorityById() {
      showAuthorityById(this.input).then(res => {
        if (res.data.status) {
          this.result = res.data.data
        }
      })
    },
    addAuthority() {
      this.$confirm("是否确认创建权限" + this.addForm, "提示", {
        iconClass: "el-icon-question",//自定义图标样式
        confirmButtonText: "确认",//确认按钮文字更换
        cancelButtonText: "取消",//取消按钮文字更换
        showClose: true,//是否显示右上角关闭按钮
        type: "warning",//提示类型  success/info/warning/error
      }).then(() => {
        createAuthority(this.addForm).then(res => {
          if (res.data.status) {
            this.getMyAuthority()
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
      })
    },
    revokeAuthority(id) {
      revokeAuthority(this.selected, id).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.showStaffsByAuthority()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    showStaffsByAuthority() {
      showGranteesGrantedByMeByAuthorityId(this.selected, 1, 50).then(res => {
        if (res.data.status) {
          this.staffList = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    removeAuthority(id) {
      this.$confirm(
          '是否确认删除此权限?',

      )
      removeAuthority(id).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.getAuthoritiesOwnedByMe()
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
